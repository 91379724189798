import makeStyles from '@mui/styles/makeStyles'
import BillboardAd from 'components/BillboardAd'
import Layout from 'components/Layout'
import OrganizationJsonLd from 'components/seo/OrganizationJsonLd'
import WebsiteJsonLd from 'components/seo/WebsiteJsonLd'
import SwiperCategories from 'components/SwiperCategories'
import SwiperLiveStreams from 'components/SwiperLiveStreams'
import SwiperRecommendations from 'components/SwiperRecommendations'
import SwiperSeries from 'components/SwiperSeries'
import SwiperShows from 'components/SwiperShows'
import SwiperStations from 'components/SwiperStations'
import { getCategories } from 'lib/categories'
import { RANGE_2DAYS, RANGE_7DAYS, SORT_CLICKS, SORT_EXPIRYDATE, SORT_RANKING, TITLE_EXPIRING, TITLE_JUST_ADDED, TITLE_TOP20_2DAYS, TITLE_TOP20_7DAYS } from 'lib/constants'
import { getStationsWithEPGData } from 'lib/epg-data'
import { getRecommendations } from 'lib/recommendations'
import { getSeries } from 'lib/series'
import { getShows, getShowsInCategory } from 'lib/shows'
import { getImageURL, sortByRemainingHours } from 'lib/utils'
import Head from 'next/head'

const title = 'Sendung verpasst? Ihre Mediathek für alle TV-Sender'

const useStyles = makeStyles((theme) => ({
  heading: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    fontSize: 0,
    clip: 'rect(1px, 1px, 1px, 1px)'
  },
  billboard: {
    marginBottom: theme.spacing(3)
  }
}))

export default function Home ({ top20days7, top20days2, serien, movies, dokus, comedy, stations, categories, justFound, dailySoaps, expiring, highlights }) {
  const classes = useStyles()

  let lcpImage = null
  if (top20days7.length > 0) {
    const firstItem = top20days7[0]._source
    lcpImage = getImageURL(firstItem.image, firstItem.stations[0].slug)
  }
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content='Alle Filme und Serien von ARD, ZDF, RTL, Sat1, Pro7, VOX, Kabel 1 etc. kostenlos abrufen - einfach, schnell und legal' />
        {lcpImage &&
          <link rel='preload' as='image' href={lcpImage} />}
      </Head>
      <OrganizationJsonLd />
      <WebsiteJsonLd />
      <Layout>
        <h1 className={classes.heading}>{title}</h1>
        <BillboardAd slot={process.env.NEXT_PUBLIC_ADSENSE_SLOT_FRONT_LEADERBOARD} className={classes.billboard} />
        <SwiperShows title={TITLE_TOP20_7DAYS} items={top20days7} counter clicks href='/top' />
        <SwiperRecommendations title='Aktuelle Highlights' items={highlights} />
        <SwiperShows title={TITLE_TOP20_2DAYS} items={top20days2} clicks href='/trends' />
        <SwiperStations stations={stations} />
        <SwiperShows title='Serien' items={serien} href='/rubriken/serien' />
        <SwiperSeries title='Daily Soaps' items={dailySoaps} />
        <SwiperShows title='Filme' items={movies} href='/rubriken/filme' />
        <SwiperCategories categories={categories} />
        <SwiperShows title='Dokus' items={dokus} href='/rubriken/dokus' />
        <SwiperShows title='Comedy und Shows' items={comedy} href='/rubriken/comedyshows' />
        <SwiperLiveStreams stations={stations} />
        <SwiperShows title={TITLE_JUST_ADDED} items={justFound} href='/search?sort=date' />
        <SwiperShows title={TITLE_EXPIRING} items={expiring} expiryDate />
      </Layout>
    </>
  )
}

export async function getStaticProps () {
  const top20days7Result = await getShows({ sort: SORT_CLICKS, range: RANGE_7DAYS })
  const top20days2Result = await getShows({ sort: SORT_CLICKS, range: RANGE_2DAYS, excludeCategories: ['dailysoaps', 'dokusoaps'] })
  const serienResult = await getShowsInCategory('serien', RANGE_7DAYS)
  const moviesResult = await getShowsInCategory('filme', RANGE_7DAYS)
  const dokusResult = await getShowsInCategory('dokus', RANGE_7DAYS)
  const comedyResult = await getShowsInCategory('comedyshows', RANGE_7DAYS)
  const dailySoapsResult = await getSeries({ category: 'dailysoaps', sort: SORT_RANKING })
  const justFoundResult = await getShows({ sort: SORT_RANKING })
  const expiringResult = await getShows({ sort: SORT_EXPIRYDATE, trailer: false })
  const highlightsResult = await getRecommendations()

  const top20days7 = top20days7Result.hits.hits
  const top20days2 = top20days2Result.hits.hits
  const serien = serienResult.hits.hits
  const movies = moviesResult.hits.hits
  const dokus = dokusResult.hits.hits
  const comedy = comedyResult.hits.hits
  const stations = await getStationsWithEPGData()
  const categories = getCategories()
  const dailySoaps = dailySoapsResult.hits.hits
  const justFound = justFoundResult.hits.hits
  const expiring = sortByRemainingHours(expiringResult.hits.hits)
  const highlights = highlightsResult.hits.hits

  return {
    props: {
      top20days7,
      top20days2,
      serien,
      movies,
      dokus,
      comedy,
      stations,
      categories,
      dailySoaps,
      justFound,
      expiring,
      highlights
    },
    revalidate: parseInt(process.env.CACHE_FRONT)
  }
}
