import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Navigation, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import CardLiveStream from './CardLiveStream'

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(7)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  swiperSlide: {
    width: '202px',
    marginRight: theme.spacing(1)
  },
  next: {
    border: '1px solid red'
  }
}))

export default function SwiperLiveStreams ({ stations }) {
  const classes = useStyles()

  return (
    <section className={classes.section}>
      <div className={classes.header}>
        <Typography component='h2' variant='h2' gutterBottom>Jetzt live ansehen</Typography>
      </div>
      <Swiper
        modules={[Navigation, A11y]}
        navigation
        slidesPerView='auto'
        lazyPreloadPrevNext={2}
        watchOverflow
        className='row-videos'
        breakpoints={{
          450: {
            slidesPerGroup: 2,
            lazy: { loadPrevNextAmount: 4 }
          },
          670: {
            slidesPerGroup: 3,
            lazy: { loadPrevNextAmount: 6 }
          },
          880: {
            slidesPerGroup: 4,
            lazy: { loadPrevNextAmount: 8 }
          },
          1080: {
            slidesPerGroup: 5,
            lazy: { loadPrevNextAmount: 10 }
          },
          1300: {
            slidesPerGroup: 6,
            lazy: { loadPrevNextAmount: 12 }
          }
        }}
      >
        {stations.map((station) => (
          <SwiperSlide key={station.slug} className={classes.swiperSlide}>
            <CardLiveStream station={station} width={200} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}
