import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import { Navigation, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import CardStation from 'components/CardStation'
import Link from 'next/link'
import * as gtag from 'lib/gtag'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  swiperSlide: {
    width: '122px',
    marginRight: theme.spacing(1)
  },
  section: {
    marginBottom: theme.spacing(7)
  },
  showAll: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main
    }
  }
}))

export default function SwiperStationsInternal ({ stations }) {
  const classes = useStyles()

  const logEvent = (event) => {
    gtag.event('alle_anzeigen')
  }

  return (
    (
      <section className={classes.section}>
        <div className={classes.header}>
          <Typography component='h2' variant='h2' gutterBottom>Alle Sender</Typography>
          <Link
            href='/sender'
            prefetch={false}
            className={`show-all ${classes.showAll}`}
            onClick={logEvent}
          >Alle anzeigen
          </Link>
        </div>
        <Swiper
          modules={[Navigation, A11y]}
          navigation
          slidesPerView='auto'
          lazyPreloadPrevNext={2}
          watchOverflow
          breakpoints={{
            286: {
              slidesPerGroup: 2,
              lazy: { loadPrevNextAmount: 5 }
            },
            414: {
              slidesPerGroup: 3,
              lazy: { loadPrevNextAmount: 6 }
            },
            542: {
              slidesPerGroup: 4,
              lazy: { loadPrevNextAmount: 8 }
            },
            689: {
              slidesPerGroup: 5,
              lazy: { loadPrevNextAmount: 10 }
            },
            819: {
              slidesPerGroup: 6,
              lazy: { loadPrevNextAmount: 12 }
            },
            949: {
              slidesPerGroup: 7,
              lazy: { loadPrevNextAmount: 14 }
            },
            1080: {
              slidesPerGroup: 8,
              lazy: { loadPrevNextAmount: 16 }
            },
            1209: {
              slidesPerGroup: 9,
              lazy: { loadPrevNextAmount: 18 }
            }
          }}
        >
          {stations.map((station) => (
            <SwiperSlide key={station.slug} className={classes.swiperSlide}>
              <CardStation station={station} inSwiper />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    )
  )
}
