import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Divider from '@mui/material/Divider'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import * as gtag from 'lib/gtag'

const useStyles = makeStyles((theme) => ({
  card: {
    marginLeft: '1px',
    marginRight: '1px',
    marginBottom: '3px',
    width: '200px',
    height: '214px'
  },
  button: {
    zIndex: 10000,
    backgroundColor: alpha('#ffffff', 0.9),
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    right: 0,
    margin: 'auto',
    display: 'block',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '80px',
    height: '80px'
  },
  play: {
    color: theme.palette.primary.main,
    fontSize: '60px',
    position: 'relative',
    top: '9px'
  },
  schedule: {
    height: '20px'
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  actionArea: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  image: {
    margin: theme.spacing(3),
    width: '152px',
    position: 'relative',
    '&[alt]:after': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: 'attr(alt)',
      fontSize: '1.5rem',
      backgroundColor: theme.palette.background.paper
    }
  }
}))

export default function CardLiveStream ({ station, linkCard }) {
  const classes = useStyles()

  const logEvent = () => {
    gtag.event('livetv', {
      station: station.livetv
    })
  }

  return (
    <Card className={classes.card}>
      <CardActionArea
        onClick={logEvent}
        className={classes.actionArea}
        href={station.livetv}
        rel='noopener noreferrer'
        data-ga-category='livetv'
      >
        <span>
          <CardMedia
            component='img'
            width='152'
            height='85'
            src={station.logoWhite}
            title={station.name + ': ' + station.currentShow}
            alt={station.name}
            className={classes.image}
            loading='lazy'
          />
          <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
        </span>
        <Divider />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant='body2' color='textSecondary' className={classes.schedule} component='p'>
            {station.currentSchedule}
          </Typography>
          <Typography variant='body2' component='p' className={classes.title}>
            {station.currentShow}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
