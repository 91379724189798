import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import Ad from 'components/Ad'
import CardShow from 'components/CardShow'
import Link from 'next/link'
import React from 'react'
import { Navigation, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as gtag from 'lib/gtag'

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(7)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  showAll: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main
    }
  },
  swiperSlide: {
    width: '202px',
    marginRight: theme.spacing(1)
  },
  next: {
    border: '1px solid red'
  },
  '@global': {
    '.ad-card': {
      width: '200px',
      height: '295px'
    }
  }
}))

export default function SwiperShows ({ title, items, counter = false, clicks = false, expiryDate = false, href, adPosition = 0, adRight = false }) {
  const classes = useStyles()

  const logEvent = (event) => {
    gtag.event('alle_anzeigen')
  }

  if (items && items.length > 0) {
    return (
      (
        <section className={classes.section} data-test={title}>
          <div className={classes.header}>
            <Typography component='h2' variant='h2' gutterBottom>{title}</Typography>
            {href
              ? <Link
                  href={href}
                  prefetch={false}
                  className={`show-all ${classes.showAll}`}
                  onClick={logEvent}
                >Alle anzeigen
                </Link>
              : <span />}
          </div>
          <Swiper
            modules={[Navigation, A11y]}
            navigation
            slidesPerView='auto'
            lazyPreloadPrevNext={2}
            watchOverflow
            className='row-videos'
            breakpoints={{
              450: {
                slidesPerGroup: 2,
                lazy: { loadPrevNextAmount: 4 }
              },
              670: {
                slidesPerGroup: 3,
                lazy: { loadPrevNextAmount: 6 }
              },
              880: {
                slidesPerGroup: 4 - adRight,
                lazy: { loadPrevNextAmount: 8 - adRight }
              },
              1080: {
                slidesPerGroup: 5 - adRight,
                lazy: { loadPrevNextAmount: 10 - adRight }
              },
              1300: {
                slidesPerGroup: 6 - adRight,
                lazy: { loadPrevNextAmount: 12 - adRight }
              }
            }}
          >
            {items.map(({ _id, _source }, index) => (
              <SwiperSlide key={index} className={classes.swiperSlide}>
                <CardOrAd index={index} adPosition={adPosition} show={_source} counter={counter} clicks={clicks} expiryDate={expiryDate} />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      )
    )
  } else {
    return (
      <span>&nbsp;</span>
    )
  }
}

function CardOrAd ({ index, adPosition, show, counter, clicks, expiryDate, cardChanged }) {
  if (index + 1 === adPosition) {
    return (
      <Ad slot={process.env.NEXT_PUBLIC_ADSENSE_SLOT_CARD} classname='ad-card' key={Math.random()} />
    )
  } else {
    return (
      <CardShow props={show} position={counter ? index + 1 : null} clickCounter={clicks} expiryDate={expiryDate} inSwiper cardChanged={cardChanged} />
    )
  }
}
